// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stile-aggiungi-js": () => import("./../../../src/pages/stile-aggiungi.js" /* webpackChunkName: "component---src-pages-stile-aggiungi-js" */),
  "component---src-pages-vedi-i-nostri-js": () => import("./../../../src/pages/vedi-i-nostri.js" /* webpackChunkName: "component---src-pages-vedi-i-nostri-js" */)
}

